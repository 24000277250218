var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_vm._m(0),_c('MainContainer',[_c('section',{staticClass:"history has-background-white-ter",staticStyle:{"padding-bottom":"5em"}},[_c('section-title-container',{attrs:{"title":"Bestellen via Whatsapp"}}),_c('div',{staticClass:"container is-fluid"},[_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-12-mobile is-10-tablet is-offset-1 has-text-centered"},[_c('ContentContainer',{attrs:{"content":{

                text: "Snel even bestellen voor morgen? Dat kan nu bij Bakkerij Broodhuys! Scan de QR-code en je komt in onze WhatsApp Shop. Kan je niet scannen klik dan op de QR-code.",
                image: 'images/QR_LandingPage_Bakkerij_tBroodhuys.png'
              },"link":"https://87.orderlemon.com/","reverse":"","fullwidth":""}})],1)])])],1),_c('section',{staticClass:"history mb-6"},[_c('SectionTitleContainer',{attrs:{"title":"Het begin","light":""}}),_c('ContentContainer',{attrs:{"content":{
        text: '‘t Broodhuys is overgenomen in 2010. Twee gepassioneerde werknemers, Peter en Mike, kregen de mogelijkheid de bakkerij over te nemen van Ko en Trudy Koenis.<br/>',
        image: 'images/customer/Bakkers.jpg'
      },"light":""}},[_c('button',{staticClass:"btn-default bh-button mx-5 is-light",on:{"click":function($event){return _vm.$router.push({ 'name': 'HetBegin' })}}},[_vm._v("LEES VERDER")])])],1)])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hero mb-0"},[_c('div',{staticClass:"hero-filter"}),_c('img',{staticClass:"hero-logo pl-5 pr-5",attrs:{"src":require("../assets/images/broodhuys_logo.png"),"alt":"bread"}}),_c('div',{staticClass:"arrow-container"},[_c('div',{staticClass:"arrow"},[_c('div',{staticClass:"fa fa-chevron-down"})])])])}]

export { render, staticRenderFns }