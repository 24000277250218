<template>
	<div class="home">
		<div class="hero mb-0">
			<div class="hero-filter"></div>
			<img
				class="hero-logo pl-5 pr-5"
				src="../assets/images/broodhuys_logo.png"
        alt="bread"
      />
			<div class="arrow-container">
				<div class="arrow">
          <div class="fa fa-chevron-down"></div>
        </div>
			</div>
		</div>
    <MainContainer>



        <section class="history has-background-white-ter" style="padding-bottom: 5em;">

          <section-title-container title="Bestellen via Whatsapp"></section-title-container>

          <div class="container is-fluid">

            <div class="columns">

              <div class="column is-12-mobile is-10-tablet is-offset-1 has-text-centered">

                <ContentContainer :content="{

                text: `Snel even bestellen voor morgen? Dat kan nu bij Bakkerij Broodhuys! Scan de QR-code en je komt in onze WhatsApp Shop. Kan je niet scannen klik dan op de QR-code.`,
                image: 'images/QR_LandingPage_Bakkerij_tBroodhuys.png'
              }" link="https://87.orderlemon.com/" reverse fullwidth>
                </ContentContainer>


              </div>
            </div>
          </div>

        </section>



        <section class="history mb-6">
          <SectionTitleContainer title="Het begin" light/>
          <ContentContainer :content="{
        text: '‘t Broodhuys is overgenomen in 2010. Twee gepassioneerde werknemers, Peter en Mike, kregen de mogelijkheid de bakkerij over te nemen van Ko en Trudy Koenis.<br/>',
        image: 'images/customer/Bakkers.jpg'
      }" light>
            <button class="btn-default bh-button mx-5 is-light" @click="$router.push({ 'name': 'HetBegin' })">LEES VERDER</button>
          </ContentContainer>

        </section>

        <!-- <section class="most-wanted is-inline-block has-background-white-bis">
          <SectionTitleContainer class="mb-5" title="Most wanted" />
          <div class="columns mb-6">
            <div class="column is-10 is-offset-1">
              <div class="columns">
               <div class="column is-4"
                    v-for="item in [
                      {name: 'Bruin brood', price: 2.6, imgPath: 'images/bread_outside.jpeg'},
                      {name: 'Bruin brood', price: 2.8, imgPath: 'images/bread_outside.jpeg'},
                      {name: 'Bruin brood', price: 2, imgPath: 'images/bread_outside.jpeg'}
                    ]" :key="item">
                 <Product :imgPath="item.imgPath" :name="item.name" :price="item.price"/>
               </div>
              </div>
            </div>
          </div>
        </section> -->

    </MainContainer>
	</div>
</template>

<style lang="scss" scoped>

.hero {
	position: relative;
	width: 100%;
	height: 100vh;
	background-image: url("../assets/images/Human_holding_bread.jpeg");
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
	box-shadow: 0 0 50px #000;
  z-index: 99;
	.hero-logo {
		width: 600px;
		height: auto;
		margin-left: 50%;
		margin-top: 50vh;
		transform: translateX(-50%) TranslateY(-50%);
	}

	.hero-filter {
		width: 100%;
		height: 100%;
		position: Absolute;
		left: 0;
		top: 0;
		background-color: #00000040;
	}

	.arrow-container {
		position: absolute;
		display: inline-block;
		bottom: 20px;
		left: 40px;

		@keyframes upDown {
			0% {
				// transform: rotateZ(90deg) scaleY(3.1) translateX(-10px);
        transform: translateY(-10px);
			}
			50% {
				// transform: rotateZ(90deg) scaleY(3.1) translateX(10px);
        transform: translateY(10px);
			}
			100% {
				// transform: rotateZ(90deg) scaleY(3.1) translateX(-10px);
        transform: translateY(-10px);
			}
		}
		.arrow {
			font-size: 40px;
			color: #fff;
			//transform: rotateZ(90deg) scaleY(3.1);
			animation: upDown 1000ms 3 forwards 800ms;
			// letter-spacing: -4px;
		}
	}
}

.product{

  .product-image-container{
    padding: 30px;
    border: 5px solid #000000;
    .product-image{

    }
  }
  .product-info-container{
    padding: 30px;
    border: 5px solid #000000;
    .product-info{

    }
  }
}



</style>

<script>
import MainContainer from "@/components/Container/MainContainer";
import ContentContainer from "@/components/Container/ContentContainer";
import SectionTitleContainer from "@/components/Container/SectionTitleContainer";
// import Product from "@/components/Product";
// import WhatsappButton from "@/components/WhatsappButton";

export default {
	name: "Home",
  components: {
    // WhatsappButton,
    MainContainer,
    ContentContainer,
    SectionTitleContainer,
    // Product
  }
};
</script>
